import React from 'react';
import { useEffect } from 'react';
import classes from './Home.module.css';
import Marquee from 'react-fast-marquee';
import Diamond from '../../Assets/diamond1.jpg';
import Gold from '../../Assets/gold1.jpg';
import Silver from '../../Assets/silver1.jpg';
import { Link } from 'react-router-dom';
import Review1 from '../../Assets/review1.png';
import Review2 from '../../Assets/review2.png';
import Image1 from '../../Assets/image1.jpg';
import Wed1 from '../../Assets/wed1.jpg';
import Wed7 from '../../Assets/wed7.jpeg';
import Wed3 from '../../Assets/wed3.jpg';
// import Image2 from '../../Assets/image2.jpg';
import Image3 from '../../Assets/image3.jpg';
import Odisha1 from '../../Assets/odisha1.jpg';
import Odisha2 from '../../Assets/odisha2.jpg';
import Odisha3 from '../../Assets/odisha3.jpg';
import Image4 from '../../Assets/image4.jpg';
import Image5 from '../../Assets/image5.jpg';
import Image6 from '../../Assets/image6.jpg';
import Image7 from '../../Assets/image7.jpg';
import Image8 from '../../Assets/image8.jpg';
import Image10 from '../../Assets/image10.jpg';
import Image11 from '../../Assets/image11.jpg';
import Image12 from '../../Assets/image12.jpg';
import Image13 from '../../Assets/image13.jpg';
import Image14 from '../../Assets/image14.jpg';
import Image15 from '../../Assets/image15.jpg';
import Image16 from '../../Assets/image16.jpeg';
import Image17 from '../../Assets/image17.jpg';
import NewImage1 from '../../Assets/newImage1.jpeg'
import Branch1 from '../../Assets/branch1.jpg'
import Branch2 from '../../Assets/branch2.jpg'

// import InstagramIcon from '@mui/icons-material/Instagram';
// import YouTubeIcon from '@mui/icons-material/YouTube';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.home}>
      <img className={classes.bannerImage} src={Image1} alt='' />

      <div className={classes.tagline}>
        Weaving Love's Tapestry, Frame By Frame
      </div>

      <div className={classes.portraitImages}>
        <img className={classes.portraitImage1} src={Image3} alt='' />
        <img className={classes.portraitImage2} src={Wed7} alt='' />
        <img className={classes.portraitImage3} src={Image4} alt='' />
        <img className={classes.portraitImage4} src={Wed3} alt='' />
        <img className={classes.portraitImage5} src={Image6} alt='' />
      </div>

      <div className={classes.vision}>
        <div className={classes.heading}>
          Weaving dreams into
          <span className={classes.cinematic}> Cinematic</span> <br />
          reality.
        </div>
        <div className={classes.description}>
          Immersing in every
          <span className={classes.gradientEmotion}> emotion</span>, our lens
          captures the essence, With unmatched artistry, we paint love's vivid
          presence. From start to finish, we redefine
          <span className={classes.gradientWedding}> wedding</span>{' '}
          videography's prowess.
        </div>
      </div>
      <div className={classes.servicesHeading}>Services</div>
      <div className={classes.services}>
        <div className={classes.service}>
          <img className={classes.serviceImg1} src={Wed1} alt='' />
          <div className={classes.serviceDesc}>
            <span className={classes.name}>Wedding</span>
            <span className={classes.nameDesc}>
              Capture the timeless moments of your special day with our
              unparalleled expertise in wedding videography.
            </span>
            <Link className={classes.serviceLink} to='/services'>
              <span className={classes.see}>see more</span>
            </Link>
          </div>
        </div>
        <div className={classes.service}>
          <img className={classes.serviceImg} src={Image8} alt='' />
          <div className={classes.serviceDesc}>
            <span className={classes.name}>Pre Wedding</span>
            <span className={classes.nameDesc}>
              Embark on a magical journey with our exceptional pre-wedding
              videography services at "We Record Best Pre Wedding.
            </span>
            <Link className={classes.serviceLink} to='/services'>
              <span className={classes.see}>see more</span>
            </Link>
          </div>
        </div>
        <div className={classes.service}>
          <img
            className={classes.serviceImg}
            src='https://images.pexels.com/photos/2952834/pexels-photo-2952834.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            alt=''
          />
          <div className={classes.serviceDesc}>
            <span className={classes.name}>Event Photography</span>
            <span className={classes.nameDesc}>
            Preserve the magic of your special moments with our expert event photography services.
            </span>
            <Link className={classes.serviceLink} to='/services'>
              <span className={classes.see}>see more</span>
            </Link>
          </div>
        </div>
        <div className={classes.service}>
          <img className={classes.serviceImg} src='https://images.pexels.com/photos/2510428/pexels-photo-2510428.jpeg?auto=compress&cs=tinysrgb&w=800' alt='' />
          <div className={classes.serviceDesc}>
            <span className={classes.name}>Music Video</span>
            <span className={classes.nameDesc}>
            Our music video shoots capture the rhythm and soul of every song, bringing artists' visions to life with creativity and flair.
            </span>
            <Link className={classes.serviceLink} to='/services'>
              <span className={classes.see}>see more</span>
            </Link>
          </div>
        </div>
      </div>

      <div className={classes.five}>
        <p>
          Pinnacle of
          <span className={classes.weddingText}>
            wedding <br />
          </span>
          film artistry.
        </p>
      </div>
      {/* <div className={classes.projectSection}>
        <div className={classes.projectHeading}>Projects</div>
        <div className={classes.projectDescription}>
          We Capture Beautiful Moment Of Your Life In Our Works
        </div>
        <div className={classes.marquee1}>
          <Marquee>
            <img className={classes.projectImage} src={Image11} alt='' />
            <img className={classes.projectImage} src={Image12} alt='' />
            <img className={classes.projectImage} src={Image13} alt='' />
            <img className={classes.projectImage} src={Image14} alt='' />
            <img className={classes.projectImage} src={Image15} alt='' />
            <img className={classes.projectImage} src={Image16} alt='' />
            <img className={classes.projectImage} src={Image17} alt='' />
          </Marquee>
          <Marquee className={classes.marquee2}>
            <div className={classes.couples}>Aman & Rupali</div>
            <div className={classes.couples}>Romeo & Deepa</div>
            <div className={classes.couples}>Aman & Rupali</div>
            <div className={classes.couples}>Romeo & Deepa</div>
            <div className={classes.couples}>Aman & Rupali</div>
            <div className={classes.couples}>Romeo & Deepa</div>
            <div className={classes.couples}>Aman & Rupali</div>
          </Marquee>
          <Marquee className={classes.marquee2}>
            <div className={classes.address}>Ranchi, Jharkhand</div>
            <div className={classes.address}>Kolkata, WB</div>
            <div className={classes.address}>Ranchi, Jharkhand</div>
            <div className={classes.address}>Kolkata, WB</div>
            <div className={classes.address}>Ranchi, Jharkhand</div>
            <div className={classes.address}>Kolkata, WB</div>
            <div className={classes.address}>Ranchi, Jharkhand</div>
          </Marquee>
        </div>
      </div> */}
      <div className={classes.plansHeading}>Plans</div>
      <div className={classes.plans}>
        <Link to='/plans'>
          <img className={classes.plan1} src={Silver} alt='' />
        </Link>
        <Link to='/plans'>
          <img className={classes.plan2} src={Diamond} alt='' />
        </Link>
        <Link to='/plans'>
          <img className={classes.plan3} src={Gold} alt='' />
        </Link>
      </div>
      <div className={classes.reviewHeading}>Our Branches</div>
      <div className={classes.branches}>
          <img className={classes.branch} src={Branch1} alt="" />
          <img className={classes.branch} src={Branch2} alt="" />
      </div>
      <div className={classes.line}></div>
      <footer>
        <div className={classes.serviceFoot}>
          <span className={classes.serviceHead}>Services</span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/services'>
              Wedding
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/services'>
              Pre Wedding
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/services'>
              Wedding Song
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/services'>
              Celebrity Guest
            </Link>
          </span>
        </div>
        <div className={classes.plansFoot}>
          <span className={classes.planHead}>Plans</span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/plans'>
              Diamond
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/plans'>
              Gold
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/plans'>
              Silver
            </Link>
          </span>
        </div>
        <div className={classes.aboutFoot}>
          <span className={classes.aboutHead}>About</span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/about'>
              Company
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/about'>
              Team
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/about'>
              Office
            </Link>
          </span>
        </div>
        <div className={classes.supportFoot}>
          <span className={classes.supportHead}>Support</span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/support'>
              Contact
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link className={classes.footerLink} to='/support'>
              Visit
            </Link>
          </span>
          <span className={classes.bottom}>
            <Link
              className={classes.footerLink}
              to='https://forms.gle/KRHtWRPK1d6Ud3er7'
            >
              jobs
            </Link>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Home;
