import React from 'react';
import { useEffect } from 'react';
import classes from './Projcects.module.css';
import Marquee from 'react-fast-marquee';
import Image28 from '../../Assets/image28.jpg';
import Image29 from '../../Assets/image29.jpg';
import Image30 from '../../Assets/image30.jpg';
import Image31 from '../../Assets/image31.jpg';
import Image32 from '../../Assets/image32.jpg';
import Image33 from '../../Assets/image33.jpg';
import Image34 from '../../Assets/image34.jpg';
import Image35 from '../../Assets/image35.jpg';
import Image36 from '../../Assets/image36.jpg';
import Image37 from '../../Assets/image37.jpg';
import Image38 from '../../Assets/image38.jpg';
import Image39 from '../../Assets/image39.jpg';
import Image40 from '../../Assets/image40.jpg';
import Image41 from '../../Assets/image41.jpg';
import Image42 from '../../Assets/image42.jpg';
import Image43 from '../../Assets/image43.jpg';
import Image44 from '../../Assets/image44.jpg';
import Image45 from '../../Assets/image45.jpg';
import Image46 from '../../Assets/image46.jpg';
import Image47 from '../../Assets/image47.jpg';
import Image48 from '../../Assets/image48.jpg';
import Image49 from '../../Assets/image49.jpg';
import Image50 from '../../Assets/image50.jpg';
import Image51 from '../../Assets/image51.jpg';
import Image52 from '../../Assets/image52.jpg';
import Image53 from '../../Assets/image53.jpg';
import Image54 from '../../Assets/image54.jpg';
import Image55 from '../../Assets/image55.jpg';
import Image56 from '../../Assets/image56.jpg';
import Image57 from '../../Assets/image57.jpg';
import Image58 from '../../Assets/image58.jpg';
import Image59 from '../../Assets/image59.jpg';
import Image60 from '../../Assets/image60.jpg';
import Image61 from '../../Assets/image61.jpg';
import Video1 from '../../Assets/wedding2.mp4';
import Flowers from '../../Assets/flowers.jpg';
import Video2 from '../../Assets/prewedding2.mp4';
import Video3 from '../../Assets/prewedding3.mp4';
import Video4 from '../../Assets/reel1.mp4';
import Wed1 from '../../Assets/wed1.jpeg';
import Wed4 from '../../Assets/wed4.jpeg';
import Wed8 from '../../Assets/wed8.jpeg';
import Wed10 from '../../Assets/wed10.jpeg';
import Wed9 from '../../Assets/wed9.jpeg';
import Wed6 from '../../Assets/wed6.jpeg';
import Wed7 from '../../Assets/wed7.jpeg';
import Wed2 from '../../Assets/wed2.jpeg';
import Wed5 from '../../Assets/wed5.jpeg';
import Sahibganj1 from '../../Assets/sahibganj1.jpg';
import Sahibganj2 from '../../Assets/sahibganj2.jpg';
import Sahibganj3 from '../../Assets/sahibganj3.jpg';
import OdishaReel from '../../Assets/odishaReel.mp4';

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.projects}>
      <div className={classes.projectInfo}>
        We invite you to explore our portfolio and experience the beauty,
        emotion, and cinematic brilliance of our previous projects.
      </div>

      <div className={classes.projectHeading}>Projects</div>
      <div className={classes.marquee}>
        <Marquee speed='30' pauseOnHover='true'>
          <img className={classes.marqueeImage} src={Image28} alt='' />
          <img className={classes.marqueeImage} src={Image29} alt='' />
          <img className={classes.marqueeImage} src={Wed1} alt='' />
          <img className={classes.marqueeImage} src={Image31} alt='' />
          <img className={classes.marqueeImage} src={Sahibganj3} alt='' />
          <img className={classes.marqueeImage} src={Wed8} alt='' />
          <img className={classes.marqueeImage} src={Image34} alt='' />
        </Marquee>
        <Marquee speed='28' direction='right' pauseOnHover='true'>
          <img className={classes.marqueeImage} src={Image35} alt='' />
          <img className={classes.marqueeImage} src={Wed10} alt='' />
          <img className={classes.marqueeImage} src={Image37} alt='' />
          <img className={classes.marqueeImage} src={Image38} alt='' />
          <img className={classes.marqueeImage} src={Wed4} alt='' />
          <img className={classes.marqueeImage} src={Image40} alt='' />
          <img className={classes.marqueeImage} src={Image41} alt='' />
        </Marquee>
      </div>
      <div className={classes.projectVideo}>
        <video
          className={classes.projectTvVideo}
          src={Video1}
          autoPlay
          muted
          loop
        ></video>
      </div>
      <div className={classes.projectPunchline}>
        <div className={classes.lines}>
          Our portfolio showcases a diverse range of weddings, each with its
          distinctive style and atmosphere.Our dedication to cinematic
          excellence shines through in every project.
        </div>
      </div>

      <div className={classes.art1}>
        <div className={classes.leftArt}>
          <img
            className={`${classes.smallImage} ${classes.none}`}
            src={Image42}
            alt=''
          />
          <img
            className={`${classes.smallImage} ${classes.smallImage1}`}
            src={Image43}
            alt=''
          />
          <img
            className={`${classes.smallImage} ${classes.none}`}
            src={Image44}
            alt=''
          />
          <img
            className={`${classes.smallImage} ${classes.smallImage2}`}
            src={Image45}
            alt=''
          />
        </div>
        <div className={classes.centerArt}>
          <img className={classes.centerImage} src={Flowers} alt='' />
        </div>
        <div className={classes.rightArt}>
          <img
            className={`${classes.smallImage} ${classes.smallImage3}`}
            src={Image46}
            alt=''
          />
          <img
            className={`${classes.smallImage} ${classes.none}`}
            src={Image47}
            alt=''
          />
          <img
            className={`${classes.smallImage} ${classes.smallImage4}`}
            src={Image48}
            alt=''
          />
          <img
            className={`${classes.smallImage} ${classes.none}`}
            src={Image49}
            alt=''
          />
        </div>
      </div>

      <div className={classes.artVideos}>
        <div className={classes.artVideos1}>
          <video
            className={classes.artVideo1}
            src={Video2}
            autoPlay
            muted
            loop
          ></video>
          <div className={classes.artDesc1}>Turning Moments</div>
        </div>
      </div>
      <div className={classes.artVideos2}>
        <div className={classes.artDesc2}>Into</div>
        <video
          className={classes.artVideo2}
          src={Video3}
          autoPlay
          muted
          loop
        ></video>
      </div>
      <div className={classes.artVideos3}>
        <video
          className={classes.artVideo3}
          src={OdishaReel}
          autoPlay
          muted
          loop
        ></video>
        <div className={classes.artDesc3}>Eternal Memories</div>
      </div>

      <div className={classes.mix}>
        <div className={classes.mix1}>
          <img className={classes.portrait6} src={Image50} alt='' />
          <img className={classes.landscape1} src={Wed10} alt='' />
          <img className={classes.portrait1} src={Sahibganj2} alt='' />
          <img className={classes.landscape2} src={Image53} alt='' />
          <img className={classes.portrait2} src={Wed6} alt='' />
          <img className={classes.landscape3} src={Image55} alt='' />
        </div>

        <div className={classes.mix2}>
          <img className={classes.portrait3} src={Wed5} alt='' />
          <img className={classes.landscape4} src={Image57} alt='' />
          <img className={classes.portrait4} src={Wed7} alt='' />
          <img className={classes.landscape5} src={Image59} alt='' />
          <img className={classes.portrait5} src={Sahibganj1} alt='' />
          <img className={classes.landscape6} src={Image61} alt='' />
        </div>
      </div>

      <div className={classes.married}>Happy Married Life</div>
    </div>
  );
};

export default Projects;
