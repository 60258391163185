import React from 'react';
import { useEffect } from 'react';
import classes from './Plans.module.css';
import Plan from '../../UI/Plan';
import FourKPlusIcon from '@mui/icons-material/FourKPlus';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import GroupsIcon from '@mui/icons-material/Groups';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import Person3Icon from '@mui/icons-material/Person3';
import Album1 from '../../Assets/album1.jpeg';
import Silver from '../../Assets/silver.jpg';
import Gold from '../../Assets/gold.jpg';
import Diamond from '../../Assets/diamond.jpg';
import Drone from '../../Assets/drone.png';
import Photos from '../../Assets/photos.png';
import FourCameraman from '../../Assets/4cameraman.jpg';
import SixCameraman from '../../Assets/6cameraman.jpg';
import eightCameraman from '../../Assets/8cameraman.jpg';
import K4 from '../../Assets/romeoDeepa.mp4';
import Reel1 from '../../Assets/reel1.jpg';
import Reel2 from '../../Assets/reel2.jpg';
import PreWedding from '../../Assets/image48.jpg';
import Custom from '../../Assets/custom.jpg';

const Plans = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.plans}>
        <div className={classes.choose}>Choose Plan</div>
        <div className={classes.art}>
          <div className={classes.best}>Choose From The 3 Best</div>
          <div className={classes.designed}>Plans Designed</div>
          <div className={classes.for}>For You.</div>
        </div>

        <div className={classes.allPlans}>
          <Plan
            imgSrc={Silver}
            planHeading='Silver'
            featureIcon1={<GroupsIcon />}
            feature1='2 Photographers'
            featureIcon2={<GroupsIcon />}
            feature2='2 Videographers'
            featureIcon3={<PhotoSizeSelectActualIcon />}
            feature3='Album - 200+ Photos'
            featureIcon4={<BlurOnIcon />}
            feature4='2-3 min. Highlight Video'
            featureIcon5={<BlurOnIcon />}
            feature5='3 Edited Reels'
            featureIcon6={<FourKPlusIcon />}
            feature6='Invitation Video'
            featureIcon7={<PhotoSizeSelectActualIcon />}
            feature7='500+ Photos'
          />
          <Plan
            imgSrc={Gold}
            planHeading='Gold'
            featureIcon1={<GroupsIcon />}
            feature1='3 Photographers'
            featureIcon2={<GroupsIcon />}
            feature2='3 Videographers'
            featureIcon3={<PhotoSizeSelectActualIcon />}
            feature3='Album - 250+ Photos'
            featureIcon4={<BlurOnIcon />}
            feature4='5-7 min. Highlight Video'
            featureIcon5={<FourKPlusIcon />}
            feature5='5 Edited Reels'
            featureIcon6={<LibraryMusicIcon />}
            feature6='Wedding Song'
            featureIcon7={<PhotoSizeSelectActualIcon />}
            feature7='Wedding Music Video'
          />
          <Plan
            className={classes.plan3}
            imgSrc={Diamond}
            planHeading='Diamond'
            featureIcon1={<GroupsIcon />}
            feature1='4 Photographers'
            featureIcon2={<GroupsIcon />}
            feature2='4 Videographers'
            featureIcon3={<PhotoSizeSelectActualIcon />}
            feature3='Album - 300+ Photos'
            featureIcon4={<BlurOnIcon />}
            feature4='8-10 min. Highlight Video'
            featureIcon5={<PhotoSizeSelectActualIcon />}
            feature5='10 Edited Reels'
            featureIcon6={<LibraryMusicIcon />}
            feature6='Wedding Song'
            featureIcon7={<Person3Icon />}
            feature7='Pre-Wedding Shoot'
          />

          <Plan
            className={classes.plan3}
            imgSrc={Custom}
            planHeading='Custom Plan'
            featureIcon1={<GroupsIcon />}
            feature1='Photographers'
            featureIcon2={<GroupsIcon />}
            feature2='Videographers'
            featureIcon3={<PhotoSizeSelectActualIcon />}
            feature3='Album - Photos'
            featureIcon4={<BlurOnIcon />}
            feature4='Highlight Video'
            featureIcon5={<PhotoSizeSelectActualIcon />}
            feature5='Edited Reels'
            featureIcon6={<LibraryMusicIcon />}
            feature6='Optional'
            featureIcon7={<Person3Icon />}
            feature7='Optional'
          />
        </div>

        <div className={classes.planDesc}>
          <div className={classes.planName}>Silver</div>
          <div className={classes.plansBottom}>
            <div className={classes.plan1}>
              <img className={classes.planImage1} src={Photos} alt='' />
              <div className={classes.animatedHeading}>Album Photos</div>
              <div className={classes.videoQualityPlus}>200+</div>
            </div>
            <div className={classes.plan2}>
              <div className={classes.seven}>4 Cameraman</div>
              <img
                className={classes.photographerTeam}
                src={FourCameraman}
                alt=''
              />
            </div>
            <div className={classes.plan3}>
              <img className={classes.planImage2} src={Album1} alt='' />
              <div className={`${classes.animatedHeading} ${classes.two50}`}>
                Invitation Video
              </div>
              <div className={classes.videoQualityPlus}>1</div>
            </div>
            <div className={classes.plan4}>
              <span>50 Edited Photos</span>
            </div>
            <div className={classes.plan5}>
              <div className={classes.videoQuality}>Hightlight Video</div>
              <video className={classes.k4} src={K4} autoPlay loop muted />
            </div>
            <div className={classes.plan6}>
              <img className={classes.planImage3} src={Reel1} alt='' />
              <div className={`${classes.animatedHeading} ${classes.sony}`}>
                Reel Video
              </div>
              <div className={classes.animatedText}>3 Edited Reel Videos</div>
            </div>
          </div>
        </div>

        <div className={classes.planDesc}>
          <div className={classes.planName}>Gold</div>
          <div className={classes.plansBottom}>
            <div className={classes.plan1}>
              <img className={classes.planImage4} src={Drone} alt='' />
              <div className={classes.animatedHeading}>Drone</div>
              <div className={classes.animatedText}>
                Incorporate breathtaking ariel shots using drones to add a
                unique and dynamic element to the videos.
              </div>
            </div>
            <div className={classes.plan2}>
              <div className={classes.seven}>6 Cameraman</div>
              <img
                className={classes.photographerTeam}
                src={SixCameraman}
                alt=''
              />
            </div>
            <div className={classes.plan3}>
              <img className={classes.planImage} src={Photos} alt='' />
              <div className={`${classes.animatedHeading} ${classes.two50}`}>
                Album Photos
              </div>
              <div className={classes.videoQualityPlus}>250+</div>
            </div>
            <div className={classes.plan4Wedding}>
              <span className={classes.weddingText}>Wedding Song</span>
            </div>
            <div className={classes.plan5}>
              <div className={classes.videoQuality}>Music Video</div>
              <video className={classes.k4} src={K4} autoPlay loop muted />
            </div>
            <div className={classes.plan6}>
              <img className={classes.planImage} src={Reel2} alt='' />
              <div className={`${classes.animatedHeading} ${classes.sony}`}>
                Reel Video
              </div>
              <div className={classes.animatedText}>5 Edited Reel Videos</div>
            </div>
          </div>
        </div>

        <div className={classes.planDesc}>
          <div className={classes.planName}>Diamond</div>
          <div className={classes.plansBottom}>
            <div className={classes.plan1}>
              <img className={classes.planImage4} src={Drone} alt='' />
              <div className={classes.animatedHeading}>Drone</div>
              <div className={classes.animatedText}>
                Incorporate breathtaking ariel shots using drones to add a
                unique and dynamic element to the videos.
              </div>
            </div>
            <div className={classes.plan2}>
              <div className={classes.seven}>8 Cameraman</div>
              <img
                className={classes.photographerTeam}
                src={eightCameraman}
                alt=''
              />
            </div>
            <div className={classes.plan3}>
              <img className={classes.planImage} src={Photos} alt='' />
              <div className={`${classes.animatedHeading} ${classes.two50}`}>
                Album Photos
              </div>
              <div className={classes.videoQualityPlus}>300+</div>
            </div>
            <div className={classes.plan4Diamond}>
              <span>Wedding Song</span>
            </div>
            <div className={classes.plan5}>
              <div className={classes.videoQuality}>Music Video</div>
              <video className={classes.k4} src={K4} autoPlay loop muted />
            </div>
            <div className={classes.plan6}>
              <img className={classes.planImage} src={PreWedding} alt='' />
              <div className={`${classes.animatedHeading} ${classes.sony}`}>
                Pre-Wedding Shoot
              </div>
              <div className={classes.animatedText}>
                Pre Wedding photoshoot & Videoshoot
              </div>
            </div>
          </div>
        </div>

        {/* <PlanDes
            planName='Silver'
            silverAlbumSrc={Album1}
            feature1='Album Photos'
            feature2='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
            feature3='4 Videographers'
            src1='https://images.pexels.com/photos/7311070/pexels-photo-7311070.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            src2={Photos}
            feature4='200+ Photos'
            feature5='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
            feature6='Pro Res. Video'
            feature7='4K'
            feature8='Video Recording'
            feature9='4K Camera'
            feature10='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
          />
          <PlanDes
            planName='Gold'
            feature1='Drone'
            feature2='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
            feature3='6 Videographers'
            src1='https://images.pexels.com/photos/7311070/pexels-photo-7311070.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            src2={Photos}
            feature4='300+ Photos'
            feature5='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
            feature6='Pro Res. Video'
            feature7='4K'
            feature8='Video Recording'
            feature9='4K Camera'
            feature10='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
          />
          <PlanDes
            planName='Diamond'
            feature1='Drone'
            feature2='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
            feature3='8 Videographers'
            src1='https://images.pexels.com/photos/7311070/pexels-photo-7311070.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            src2={Photos}
            feature4='400+ Photos'
            feature5='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
            feature6='Pro Res. Video'
            feature7='4K'
            feature8='Video Recording'
            feature9='4K Camera'
            feature10='Incorporate breathtaking ariel shots using drones to add a unique
            and ynamic element to the videos.'
          /> */}
      </div>
    </>
  );
};

export default Plans;